import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import CheckIn from './components/CheckIn/CheckIn';
import Login from './components/Login/Login';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<Login/>} />
        <Route path="/attendance" element={<CheckIn/>} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
