/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import Logo from '../../assets/logo.svg';
import UserIcon from '../../assets/user-icon.svg';
import IdIcon from '../../assets/id-icon.svg';
import { Form, InputGroup } from "react-bootstrap";
import WebcamModal from "../common/WebcamModal";
import Select from "react-select";
import SuccessModal from "../common/SuccessModal";
import GetEmployeeList from "../Api/GetEmployeeList";
import PostRequestAPI from "../Api/PostRequestAPI";
import { LOGOUT, VerifyEmployee } from "../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearRedux } from "../../redux/actions/adminActions";

const CheckIn = () => {
  const {accessToken} = useSelector((state)=>state.adminReducers);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(accessToken);
  useEffect(()=>{
    if(!accessToken){
      navigate("/")
    }
  },[])
  const [showModal, setShowModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [attendanceAction, setAttendenceAction] = useState("Check In");

  const [formData, setFormData] = useState({
    employee_id: "",
    emp_name_selected:{
      label:"",
      value:"",
    },
    employee_number: "",
    img:"",
    photo:""
  });
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSuccessClose = () => {
    setShowSuccessModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const [error, setErrors] = useState({
    employee_id: "",
    employee_number: "",
  });
  const validation = () => {
      let value = true;
      if (formData.employee_id === "") {
          value = false;
          error.employee_id = "Please select employee name.";
      }
      if (formData.employee_number === "") {
        value = false;
        error.employee_number = "The employee id field is required.";
    }
      setErrors({
          ...error,
          employee_id: error.employee_id,
          employee_number: error.employee_number,
      });
      if (value) {
          return true;
      } else {
          return false;
      }
  };

  const employeeVerify = async() =>{
    setDisabledButton(false);
    if (validation()) {
      setErrors({
          ...error,
          employee_id: "",
          employee_number: "",
      });
      const response = await PostRequestAPI(VerifyEmployee, formData);
      if(response?.status == 200){
          setAttendenceAction(response?.data?.data?.action);
          handleShowModal();
      }else{
        toast.error(<p className='text-start m-0'>{response?.data?.data?.error}</p>)
        setFormData((formData) => ({
          ...formData,
          emp_name_selected:{
            label:"",
            value:"",
          },
          employee_id: "",
          employee_number:"",
          photo:""
        }));
      }
    }
  }

  const handleImageCapture = (imgSrc) => {
    // setFormData("img",imgSrc);
    setDisabledButton(false);
    setFormData((formData) => ({
      ...formData,
      emp_name_selected:{
        label:"",
        value:"",
      },
      employee_id: "",
      employee_number:"",
      photo:""
    }));
    setShowModal(false);
    setShowSuccessModal(true);
    // alert("image insert")
  };


const GetEmployeeList1 = async() =>{
  const responce = await GetEmployeeList()
  if(responce?.status === 200){
    setEmployeeList(responce.data)
  }
}
var employee_options1 = employeeList?.map((item, index)=>({key:item.id, value:item?.id , label:item?.first_name + " " + item?.last_name }));

useEffect(()=>{
  GetEmployeeList1()
},[])

const handleLogout = async () =>{
   const response = await PostRequestAPI(LOGOUT,'',accessToken);
   console.log("response",response);
   if(response?.data?.success === true){
     toast.success(<p className='text-start m-0'>{response?.data?.message}</p>);
     dispatch(clearRedux());
     localStorage.clear();
     navigate("/");
    }else{
      toast.error(<p className='text-start m-0'>{response?.data?.message}</p>);
    }
}
console.log("updatedForm",formData)

    return (
      <div>
        <button className="btn btn-danger logout-btn" onClick={handleLogout}>Logout</button>
        <h3 className="mb-4">Welcome Back</h3>
      
      <div className="card card-layout">
      <div className="d-flex align-items-center hr-line justify-content-center">
        <img src={Logo} alt="Logo" className="pallet-logo" />
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
            <label className="float-left mb-2 common-label">Employee Name</label>
            <InputGroup className="mb-3">
              <InputGroup.Text className="w-5" id="basic-addon1"><img src={UserIcon} alt="Icon" /></InputGroup.Text>
              <Select
                  closeMenuOnSelect={true}
                  isClearable={true}
                  classNamePrefix="select"
                  // isRtl={true}
                  isSearchable={true}
                  options={employee_options1}
                  placeholder="Select Employee Name"
                  onChange={(e) => {
                      const empNameValue = e ? e.value : '';
                      setFormData((formData) => ({
                          ...formData,
                          emp_name_selected: e,
                          employee_id: empNameValue,
                      }));
                  }}
                  value={formData?.employee_id ? formData?.emp_name_selected : ""}
              />
            </InputGroup>

            {error.employee_id !== "" &&
                <div className="text-danger error-msg text-start">
                    {error.employee_id}
                </div>
            }
        </div>
        <div className="col-md-12">
            <label className="float-left mb-2 common-label">Employee Id</label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"><img src={IdIcon} alt="Icon" /></InputGroup.Text>
              <Form.Control
                placeholder="Employee Id"
                aria-label="Employee Id"
                aria-describedby="basic-addon1"
                className="h-40"
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); 
                  setFormData((formData) => ({
                      ...formData,
                      employee_number: trimmedValue,
                  }));
                }}
                value={formData.employee_number}
              />
            </InputGroup>
            {error.employee_number !== "" &&
                <div className="text-danger error-msg text-start">
                    {error.employee_number}
                </div>
            }
        </div>
        <div className="col-md-12">
            {/* <button className="button-18" onClick={handleShowModal}>Next</button> */}
            <button className="button-18" onClick={employeeVerify}>Next</button>
        </div>
      </div>
      </div>
      <WebcamModal show={showModal} setDisabledButton={setDisabledButton} disabledButton={disabledButton} handleClose={handleCloseModal} title={attendanceAction} onCapture={handleImageCapture} setFormData={setFormData} formData={formData}/>
      <SuccessModal show={showSuccessModal} handleSuccessClose={handleSuccessClose} title={attendanceAction} />
      </div>
      
    )
}

export default CheckIn;