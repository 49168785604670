

export const API_URL = "https://phr.appristine.in/";
export const EmployeeListURL = API_URL + "api/employees-list?react-api=yes"
export const VerifyEmployee = API_URL + "api/employee-verify"
export const uploadFile = API_URL + "api/upload-file"
export const employeeCheckIn = API_URL + "api/employee-check-in"
export const employeeCheckOut = API_URL + "api/employee-check-out"
export const LOGIN = API_URL + "api/login"
export const LOGOUT = API_URL + "api/logout"
