/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

export const initialData = {
    accessToken: "",
};


export const adminReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.GET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ActionTypes.ADMIN_LOGOUT:
            return {
                ...initialData,
            };
        default:
        return state;
    }
};