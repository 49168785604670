export const ActionTypes = {
    GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
    ADMIN_LOGOUT: "ADMIN_LOGOUT",
};

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.GET_ACCESSTOKEN,
        payload: token,
    }
}

export const clearRedux = () => {
    return {
       type: ActionTypes.ADMIN_LOGOUT,
    }
}