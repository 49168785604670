import axios from 'axios';
import { EmployeeListURL } from "../Shared/constant";

const GetEmployeeList = async () => {
  try {
      const response = await axios.get(`${EmployeeListURL}`, {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
          }
      });
      return {
          ...response.data,
          status: 200
      };
  } catch (error) {
      return {
          ...error?.response?.data,
          status: error?.response?.status
      };
  }
};

export default GetEmployeeList;


