import { Modal } from "react-bootstrap";
import React from 'react';
import TickBtn from "../../assets/tick.svg";

const SuccessModal = ({ show, handleSuccessClose,title }) =>{

    return (
        <Modal show={show} onHide={handleSuccessClose}
        style={{top:"auto",left:"auto",width:"auto"}}
        centered
        >
          <Modal.Body className="p-4">
            <div className="d-flex justify-content-center align-items-center flex-column my-2 gap-3">
            <img src={TickBtn} alt="success-icon" />
            <span className="common-label">{title} Successfully</span>
            <button className="button-18" onClick={handleSuccessClose}>Okay..!</button>
            </div>
          </Modal.Body>
        </Modal>
      );
};

export default SuccessModal;