/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import Logo from '../../assets/logo.svg';
import UserIcon from '../../assets/user-icon.svg';
import IdIcon from '../../assets/password.svg';
import EyeOpen from '../../assets/eye-open.svg';
import EyeClose from '../../assets/eye-closed.svg';
import { Form, InputGroup } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import PostRequestAPI from "../Api/PostRequestAPI";
import { LOGIN } from "../Shared/constant";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken } from "../../redux/actions/adminActions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  
  const dispatch = useDispatch();
  const {accessToken} = useSelector((state)=>state.adminReducers);
  const navigate = useNavigate();
  console.log(accessToken);
  useEffect(()=>{
    if(accessToken){
      navigate("/attendance");
    }
  },[])
  const [passwordType, setPasswordType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(EyeClose);
  const togglePassword = () => {
      if (passwordType === "password") {
          setPasswordType("text")
          setEyeIcon(EyeOpen)
          return;
      }
      setPasswordType("password")
      setEyeIcon(EyeClose)
  }
  const [formData, setFormData] = useState({
    password: "",
    email:"",
  });


  const [error, setErrors] = useState({
    password: "",
    email: "",
  });
  const validation = () => {
      let value = true;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formData.password === "") {
          value = false;
          error.password = "Please Enter Password";
        }else{
            error.password = "";
      }
      if (formData.email === "") {
        value = false;
        error.email = "Please Enter Email Address";
        }else if(!emailRegex.test(formData?.email)){
            error.email = "Please Enter a Valid Email Address";
        }else{
            error.email = "";
        }
      setErrors({
          ...error,
          password: error.password,
          email: error.email,
      });
      if (value) {
          return true;
      } else {
          return false;
      }
  };

  const handleLogin = async () =>{
    if(validation()){
        const body = {
            "email":formData?.email,
            "password":formData?.password,
        }
        const response = await PostRequestAPI(LOGIN,body);
        // console.log("response",response?.data);
        if(response?.data?.success === true){
            toast.success(<p className='text-start m-0'>{response?.data?.message}</p>);
            dispatch(getAccessToken(response?.data?.data?.token))
            navigate("/attendance");
        }else{
            toast.error(<p className='text-start m-0'>{response?.data?.message}</p>);
        }
    }
  }

console.log("updatedForm",formData)

    return (
      <div>
      <div className="card card-layout">
      <div className="d-flex align-items-center hr-line justify-content-center">
        <img src={Logo} alt="Logo" className="pallet-logo" />
      </div>
      <div className="row mt-3">
        <div className="col-md-12">
            <label className="float-left mb-2 common-label">Email Address</label>
            <InputGroup className="mb-3">
              <InputGroup.Text className="w-5" id="basic-addon1"><img src={UserIcon} alt="Icon" className="ms-1" /></InputGroup.Text>
              <Form.Control
                placeholder="Enter Email Address"
                aria-label="Email Address"
                aria-describedby="basic-addon1"
                className="h-40"
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); 
                  setFormData((formData) => ({
                      ...formData,
                      email: trimmedValue,
                  }));
                }}
                value={formData.email}
              />
            </InputGroup>

            {error.email !== "" &&
                <div className="text-danger error-msg text-start">
                    {error.email}
                </div>
            }
        </div>
        <div className="col-md-12 position-relative">
            <label className="float-left mb-2 common-label">Password</label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"><img src={IdIcon} alt="Icon" width={25} /></InputGroup.Text>
              <Form.Control
                type={passwordType}
                placeholder="Enter Password"
                aria-label="Employee Id"
                aria-describedby="basic-addon1"
                className="h-40"
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); 
                  setFormData((formData) => ({
                      ...formData,
                      password: trimmedValue,
                  }));
                }}
                value={formData.password}
              />
            </InputGroup>
              <img src={eyeIcon} width={ passwordType === "text" ? 24 : 24} onClick={(e) => togglePassword()} className={ passwordType === "text" ? "eye-icon" : "eye-icon"} />
            {error.password !== "" &&
                <div className="text-danger error-msg text-start">
                    {error.password}
                </div>
            }
        </div>
        <div className="col-md-12">
            {/* <button className="button-18" onClick={handleShowModal}>Next</button> */}
            <button className="button-18 mt-1" onClick={handleLogin}>Login</button>
        </div>
      </div>
      </div>
      </div>
      
    )
}

export default Login;