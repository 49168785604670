import { Modal } from "react-bootstrap";
import React, { useRef } from 'react';
import Webcam from "react-webcam";
import PostRequestAPI from "../Api/PostRequestAPI";
import { uploadFile } from "../Shared/constant";
import { toast } from 'react-toastify';
import { employeeCheckIn,employeeCheckOut } from "../Shared/constant";


const WebcamModal = ({ show, handleClose,title,onCapture,setFormData,formData,disabledButton,setDisabledButton }) =>{
    const webcamRef = useRef(null);

    // const capture = async() =>{
    //     const imageSrc = webcamRef.current.getScreenshot();
    //     if(imageSrc){
    //         onCapture(imageSrc);
    //         console.log("onCapture",imageSrc);
    //     }
    // }

    const capture = async () => {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const base64Response = await fetch(imageSrc);
        const blob = await base64Response.blob();
        // const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif", "image/bmp", "image/jfif"];
        setDisabledButton(true);
        const formData1 = new FormData();
        formData1.append("file", blob);
        const url = uploadFile;
        const options = {
          method: "POST",
          body: formData1,
        };
        try {
          const response = await fetch(url, options);
          const data = await response.json();
          if (data.status === false) {
            setFormData((formData) => ({
              ...formData,
              photo: "",
            }));
            toast.error("Failed to " + title + " please try again!");
          } else {
            var newImg = data.file_path;
            setFormData((formData) => ({
              ...formData,
              photo: newImg,
            }));
    
            const jsonPayload = {
              employee_id:formData.employee_id,
              photo:newImg
            }
            // Introduce a delay before making the second API call
            setTimeout(async () => {
              let api_url = employeeCheckIn;
              if (title === "Check Out") {
                api_url = employeeCheckOut;
              }
              const response = await PostRequestAPI(api_url, jsonPayload);
              if (response?.status === 200) {
                onCapture(imageSrc);
              } else {
                console.log("response?.data?.data?.message",response?.data?.message);
                toast.error(response?.data?.message);
              }
              console.log("check respo", response);
            }, 1000); // Delay in milliseconds (adjust as needed)
          }
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    };
    
    return (
        <Modal show={show} onHide={handleClose}
        style={{top:"auto",left:"auto",width:"auto"}}
        centered
        >
          <Modal.Body className="p-0">
            <div className="px-3 my-3">
            <span className="common-label">{title}</span>
            </div>
            <Webcam ref={webcamRef} screenshotFormat="image/jpeg" mirrored={true} className="webcam-layout" />
            <div className="d-flex justify-content-center align-items-center my-2">
            <button className="button-18 me-2 close-btn" onClick={handleClose}>Close</button>
            <button className="button-18" onClick={capture} disabled={disabledButton}>{title}</button>
            </div>
          </Modal.Body>
        </Modal>
      );
};

export default WebcamModal;